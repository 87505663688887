import { createSlice, configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { apiProductCategoriesV2 } from '../apis/categoryApi';
import { getAnEvent } from '../apis/eventApi';

export const fetchGetCategory = createAsyncThunk('app/fetchGetCategory', async (params, { getState, requestId }) => {
  const result = await apiProductCategoriesV2();
  return result;
});

export const fetchGetAppEvent = createAsyncThunk('app/fetchGetAppEvent', async () => {
  const result = await getAnEvent();
  return result;
});

const appSlice = createSlice({
  name: 'app',
  initialState: {
    appType: 'mom',
    popupVisible: false,
    popupText: '',
    popupButtonText: 'OK',
    popupButtonText2: '맘가이드 앱 다운받기',
    categoryData: [],
    appEvent: undefined,
    popupLastPath: undefined,
    isNoticeModalVisible: true,
  },
  reducers: {
    showNoticeModal: (state) => {
      state.isNoticeModalVisible = true;
    },
    closeNoticeModal: (state) => {
      state.isNoticeModalVisible = false;
    },
    setAppType: (state, { payload: appType }) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.appType = appType;
    },
    showPopup: (
      state,
      { payload: { text, buttonText = 'OK', popupButtonText2 = '맘가이드 앱 다운받기', popupLastPath } },
    ) => {
      state.popupVisible = true;
      state.popupText = text;
      state.popupButtonText = buttonText;
      state.popupButtonText2 = popupButtonText2;
      state.popupLastPath = popupLastPath;
    },
    closePopup: (state) => {
      state.popupVisible = false;
      state.popupLastPath = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(fetchGetCategory.fulfilled, (state, action) => {
        const categories = _.cloneDeep(action.payload);
        state.categoryData = categories;
      })
      .addCase(fetchGetAppEvent.fulfilled, (state, action) => {
        const eventRes = _.cloneDeep(action.payload);
        state.appEvent = eventRes?.data?.[0];
      });
  },
});

export const { setAppType, showPopup, closePopup, showNoticeModal, closeNoticeModal } = appSlice.actions;

export const store = configureStore({
  reducer: appSlice.reducer,
  devTools: false,
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// // Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// // Still pass action objects to `dispatch`, but they're created for us
// store.dispatch(incremented())
// // {value: 1}
// store.dispatch(incremented())
// // {value: 2}
// store.dispatch(decremented())
// // {value: 1}
